@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Imperial+Script&family=Kdam+Thmor+Pro&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
.btn-reserva {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px 25px;
  gap: 8px;
  width: fit-content;
  border: none;
  background: var(--azul-gac);
  border-radius: 10px;
  box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  cursor: pointer;
  margin: 0 0.9em;
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1);
  color: var(--fondo-defecto);
}

.lable {
  line-height: 22px;
  font-size: 15px;
  color: var(--fondo-defecto);
  font-weight: 500;
  font-family: sans-serif;
  letter-spacing: 1px;
}

.btn-reserva:hover {
  transform: scale(1.1);
  color: var(--fondo-defecto);
}
.btn-reserva:hover .lable {
  font-weight: 600;
  color: var(--amarillo-gac);
}
.btn-reserva:hover .svg-icon {
  animation: slope 1s linear infinite;
}

@keyframes slope {
  0% {
  }
  50% {
    transform: rotate(20deg);
  }
}
/* desing */

.gallery-container {
  max-width: 1200px;
  width: 95%;
  margin: 1em 0;
}

.slider-wrapper {
  position: relative;
}

.slider-wrapper .slide-button {
  position: absolute;
  top: 50%;
  outline: none;
  border: none;
  height: 50px;
  width: 50px;
  z-index: 5;
  color: #ffffff8e;
  display: flex;
  cursor: pointer;
  font-size: 2.2rem;
  background: #003893c2;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transform: translateY(-50%);
}
.slider-wrapper .slide-button svg {
  width: 25px;
  height: 25px;
}

.slider-wrapper .slide-button:hover {
  background: var(--azul-gac);
  color: #fff;
  font-weight: 700;
}

.slider-wrapper .slide-button#prev-slide {
  left: -25px;
  display: none;
}

.slider-wrapper .slide-button#next-slide {
  right: -25px;
}

.slider-wrapper .image-list {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 18px;
  font-size: 0;
  list-style: none;
  margin-bottom: 30px;
  overflow-x: auto;
  scrollbar-width: none;
}

.slider-wrapper .image-list::-webkit-scrollbar {
  display: none;
}

.slider-wrapper .image-list .image-item {
  width: 325px;
  height: 400px;
  object-fit: cover;
}

.gallery-container .slider-scrollbar {
  height: 24px;
  width: 100%;
  display: flex;
  align-items: center;
}

.slider-scrollbar .scrollbar-track {
  background: #ccc;
  width: 100%;
  height: 2px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  position: relative;
}

.slider-scrollbar:hover .scrollbar-track {
  height: 4px;
}

.slider-scrollbar .scrollbar-thumb {
  position: absolute;
  background: var(--azul-gac);
  top: 0;
  bottom: 0;
  width: 50%;
  height: 100%;
  cursor: grab;
  border-radius: inherit;
}

.slider-scrollbar .scrollbar-thumb:active {
  cursor: grabbing;
  height: 8px;
  top: -2px;
}

.slider-scrollbar .scrollbar-thumb::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: -10px;
  bottom: -10px;
}

/* Styles for mobile and tablets */
@media only screen and (max-width: 1023px) {
  .slider-wrapper .slide-button {
    display: none !important;
  }

  .slider-wrapper .image-list {
    gap: 10px;
    margin-bottom: 15px;
    scroll-snap-type: x mandatory;
  }

  .slider-wrapper .image-list .image-item {
    width: 280px;
    height: 380px;
  }

  .slider-scrollbar .scrollbar-thumb {
    width: 20%;
  }
}

.hotel-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-family: "Roboto", sans-serif;
  color: #333;
  margin: 20px;
  padding: 1em;
  text-align: justify;
}
.custom-list {
  list-style-type: none; /* Quitar el marcador predeterminado */
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  gap: 1rem;
}

.custom-list li {
  padding: 10px;
  background-color: var(--fondo-defecto);
  border: 1px solid var(--active-color);
  border-radius: 2em;
  position: relative;
}
.custom-list li:hover {
  background: var(--amarillo-gac);
  border: none;
  color: var(--azul-gac);
}
.btn-new {
  position: relative;
  padding: 10px 20px;
  border-radius: 7px;
  border: 1px solid rgb(61, 106, 255);
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px;
  background: transparent;
  color: #fff;
  overflow: hidden;
  box-shadow: 0 0 0 0 transparent;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  background: rgb(61, 106, 255);
}

.btn-new:hover {
  box-shadow: 0 0 30px 5px rgba(0, 142, 236, 0.815);
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.btn-new:hover::before {
  -webkit-animation: sh02 0.5s 0s linear;
  -moz-animation: sh02 0.5s 0s linear;
  animation: sh02 0.5s 0s linear;
}

.btn-new::before {
  content: "";
  display: block;
  width: 0px;
  height: 86%;
  position: absolute;
  top: 7%;
  left: 0%;
  opacity: 0;
  background: #fff;
  box-shadow: 0 0 50px 30px #fff;
  -webkit-transform: skewX(-20deg);
  -moz-transform: skewX(-20deg);
  -ms-transform: skewX(-20deg);
  -o-transform: skewX(-20deg);
  transform: skewX(-20deg);
}

@keyframes sh02 {
  from {
    opacity: 0;
    left: 0%;
  }

  50% {
    opacity: 1;
  }

  to {
    opacity: 0;
    left: 100%;
  }
}

.btn-new:active {
  box-shadow: 0 0 0 0 transparent;
  -webkit-transition: box-shadow 0.2s ease-in;
  -moz-transition: box-shadow 0.2s ease-in;
  transition: box-shadow 0.2s ease-in;
}

.texto {
  position: relative;
  overflow: hidden;
}

.texto::before {
  content: "";
  position: absolute;
  top: 7%;
  left: -50%; /* Inicia fuera de la vista a la izquierda */
  width: 50%; /* Ancho del pseudo-elemento */
  height: 86%;
  background: rgba(255, 255, 255, 0.4);
  transform: skewX(-20deg);
  animation: sh04 2s linear infinite;
}

@keyframes sh04 {
  0% {
    left: -50%; /* Empieza fuera de la vista a la izquierda */
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    left: 100%; /* Cruza todo el elemento */
    opacity: 0;
  }
  100% {
    left: 100%; /* Termina fuera de la vista a la derecha */
    opacity: 0;
  }
}
